<template>
  <vuestic-modal
    v-bind:large="true"
    ref="modal"
    okClass="none"
    cancelClass="none"
  >
    <div slot="title">Imagem</div>
    <el-image
      class="rounded mx-auto d-block"
      :src="link"
    >
      <div
        slot="placeholder"
        class="image-slot"
      >
        Carregando<span class="dot">...</span>
      </div>
    </el-image>
  </vuestic-modal>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
      default: '',
    },
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
  },

};
</script>
