<template>
  <vuestic-modal
    v-bind:large="true"
    ref="finalizarAcao"
    okText="Finalizar Ação"
    v-on:ok="ok"
    :cancelClass="'none'"
  >
    <h4>Finalizar ação</h4>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="input-group ">
              <textarea v-model="acaoAux.comentario_finalização"></textarea>
              <label class="control-label">
                Comentário de Finalização (500)
              </label>
            </div>
          </div>
          <span
            class="alert-danger"
            v-if="erros && erros['comentario_finalização']"
          >{{ erros['comentario_finalização'][0] }}</span>
        </div>
        <div class="col">
          <div class="form-group">
            <div class="data-group border">
              <vuestic-date-picker
                :config="{
                        altInput: true,
                        altFormat: 'd/m/Y',
                        dateFormat: 'Y-m-d'
                    }"
                id="id-date-finalizacao"
                v-model="acaoAux.data_finalização"
              />
              <span class="alert-danger"></span>
              <label
                class="control-label"
                for="id-date-finalizacao"
              >Data de Finalização</label>
            </div>
          </div>
          <span
            class="alert-danger"
            v-if="erros && erros['data_finalização']"
          >{{ erros['data_finalização'][0] }}</span>
        </div>
      </div>
    </div>
  </vuestic-modal>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    acao: {
      type: Object,
      required: true,
    },
    erros: {},
  },
  data() {
    return {
      acaoAux: {
        data_finalização: null,
        comentario_finalização: '',
      },
    };
  },
  methods: {
    open() {
      this.$refs.finalizarAcao.open();
    },
    ok() {
      this.acaoAux.situacao = 3;
      this.$emit('ok', this.acaoAux);
    },
  },
  watch: {
    acao(acao) {
      this.acaoAux = { ...acao };
      this.acaoAux.data_finalização = acao.data_finalização || moment().format('YYYY-MM-DD');
      this.acaoAux.comentario_finalização = acao.comentario_finalização;
    },
  },
};
</script>

<style>
</style>
