<template>
	<vuestic-modal
		v-bind:large="true"
		ref="largeModalEdict"
		cancelText="Cancelar"
		okClass="none"
		cancelClass="none"
	>
		<div slot="title">Editar Ação</div>
		<template>
			<template v-if="acao.situacao < 3">
				<button class="btn btn-primary btn-micro mr-2" @click="modalToEndAcao">
					Finalizar
				</button>
				<button
					class="btn btn-dark btn-micro"
					@click="modalToCancela"
					v-if="acao.responsavel_cadastro === user.funcionario.id"
				>
					Cancelar
				</button>
				<button
					v-if="user.groups[0] === 1"
					class="btn btn-danger btn-micro ml-2"
					@click="openModalDelete"
				>
					Excluir
				</button>
			</template>
			<template v-else>
				<button class="btn btn-warning btn-micro" @click="reabrirAcao">
					Reabrir
				</button>
			</template>
			<button class="btn btn-info btn-micro ml-2" @click="openModalDublicar">
				Replicar ação
			</button>
		</template>
		<hr />
		<form autocomplete="off" class="container">
			<div class="row">
				<div class="col">
					<multiselect
						v-model="ferramentasSelecionadas"
						label="ferramenta"
						:options="ferramentas"
						:disabled="acao.situacao > 2"
						:multiple="false"
					>
						<span slot="noResult">Ferramenta</span>
					</multiselect>
					<span class="alert-danger" v-if="erros && erros['origem']">
						{{ erros['origem'][0] }}
					</span>
				</div>

				<div class="col">
					<multiselect
						v-model="areasSelecionada"
						placeholder="Áreas"
						label="nome"
						selectLabel="Selecione"
						:options="areas"
						:multiple="false"
						:disabled="acao.situacao > 2"
					>
						<span slot="noResult">Área</span>
					</multiselect>
					<span class="alert-danger" v-if="erros && erros['area']">
						{{ erros['area'][0] }}
					</span>
				</div>

				<div class="col">
					<div class="form-group">
						<div class="input-group">
							<input
								:class="{ has_disable: acao.situacao > 2 }"
								:maxlength="100"
								id="simple-input"
								required="required"
								:disabled="acao.situacao > 2"
								v-model="acao.assunto"
							/>
							<span class="alert-danger"></span>
							<label class="control-label"
for="textarea-input"
								>Assunto ({{ calcularTananho(100, 'assunto') }} / 100) *</label
							>
							<br />
						</div>
					</div>
					<span class="alert-danger" v-if="erros && erros['assunto']">
						{{ erros['assunto'][0] }}
					</span>
				</div>
			</div>

			<div class="row">
				<div class="col">
					<div class="form-group">
						<div class="input-group">
							<textarea
								:class="{ has_disable: acao.situacao > 2 }"
								v-model="acao.o_que"
								:disabled="acao.situacao > 2"
								:maxlength="300"
							></textarea>
							<span></span>
							<label class="control-label"
								>O que fazer? ({{ calcularTananho(300, 'o_que') }}) / 300
								*</label
							>
						</div>
					</div>
					<span class="alert-danger" v-if="erros && erros['o_que']">
						{{ erros['o_que'][0] }}
					</span>
				</div>
				<div class="col">
					<div class="form-group">
						<div class="input-group">
							<textarea
								id="como"
								:class="{ has_disable: acao.situacao > 2 }"
								:maxlength="300"
								v-model="acao.como"
								:disabled="acao.situacao > 2"
							></textarea>
							<span class="alert-danger"></span>
							<label class="control-label" for="textarea-input">
								Como fazer? ({{ calcularTananho(300, 'como') }} / 300) *
							</label>
						</div>
					</div>
					<span class="alert-danger" v-if="erros && erros['como']">
						{{ erros['como'][0] }}
					</span>
				</div>
			</div>

			<div class="row">
				<div class="col">
					<div class="form-group">
						<div class="data-group" :class="{ desativa: acao.situacao > 2 }">
							<vuestic-date-picker
								:disable="acao.situacao > 2"
								:config="{
									altInput: true,
									altFormat: 'd/m/Y',
									dateFormat: 'Y-m-d'
								}"
								id="id-data-cadastro"
								v-model="acao.data_cadastro"
							/>
							<span class="alert-danger"></span>
							<label
								:class="{ has_disable: acao.situacao > 2 }"
								class="control-label"
								for="id-data-cadastro"
								>Data de Cadastro</label
							>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="form-group">
						<div class="data-group" :class="{ desativa: acao.situacao > 2 }">
							<vuestic-date-picker
								:disable="acao.situacao > 2"
								:config="{
									altInput: true,
									altFormat: 'd/m/Y',
									dateFormat: 'Y-m-d'
								}"
								id="id-date-prazo"
								v-model="acao.prazo"
							/>
							<span class="alert-danger"></span>
							<label
								:class="{ has_disable: acao.situacao > 2 }"
								class="control-label"
								for="id-date-prazo"
								>Prazo</label
							>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="form-group">
						<div class="input-group">
							<label class="select-control-label">Quem?</label>
							<multiselect
								:disabled="acao.situacao > 2"
								v-model="responsavelSelecionado"
								placeholder="Quem?"
								label="nome"
								selectLabel="Selecione"
								:options="responsaveis"
								:multiple="false"
							>
								<span slot="noResult">Funcionario</span>
							</multiselect>
							<span class="alert-danger" v-if="erros && erros['responsavel']">
								{{ erros['responsavel'][0] }}
							</span>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="form-group">
						<div class="input-group">
							<label class="select-control-label">Solicitante</label>
							<multiselect
								:disabled="acao.situacao > 2"
								v-model="responsavelCadastroSelecionado"
								placeholder="Solicitante"
								label="nome"
								selectLabel="Selecione"
								:options="responsaveis"
								:multiple="false"
							>
								<span slot="noResult">Funcionario não encontrado</span>
							</multiselect>
							<span
								class="alert-danger"
								v-if="erros && erros['responsavel_cadastro']"
							>
								{{ erros['responsavel_cadastro'][0] }}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="form-group">
						<div class="input-group">
							<cascader
								class="full-width"
								v-model="acao.indicador"
								placeholder="Indicadores"
								:options="indicadores"
								:value="acao.indicador"
								:disabled="acao.situacao > 2"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="form-group">
						<div class="input-group">
							<textarea
								:maxlength="500"
								type="text"
								id="simple-textarea"
								v-model="acao.comentario"
								required
							></textarea>
							<label class="control-label"
for="simple-textarea"
								>Descrição</label
							>
						</div>
						<i
							v-show="img"
							@click="openImg()"
							class="foto-icon el-icon-camera pointer"
						></i>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-center">
				<el-button
					:disabled="acao.situacao > 2"
					type="success"
					round
					@click="editarAcao"
					>Salvar Alterações</el-button
				>
			</div>
		</form>
		<!--modal de finaliza ação-->
		<finalizar-acao ref="modalToEndAcao"
:acao="acao"
v-on:ok="finalizarAcao" />
		<!--modal de cancelar ação-->
		<vuestic-modal
			v-bind:large="true"
			ref="largeModalCancelar"
			okText="Cancelar Ação"
			v-on:ok="cancelarAcao"
			:okDisabled="!acao.comentario_finalização"
			:cancelClass="'none'"
		>
			<div class="row">
				<div class="col">
					<div class="form-group">
						<div class="input-group">
							<textarea v-model="acao.comentario_finalização"></textarea>
							<label class="control-label">Comentário de Cancelamento</label>
						</div>
					</div>
				</div>
			</div>
		</vuestic-modal>
		<vuestic-modal ref="modalToDelete" v-on:ok="deletarAcao()">
			<div slot="title">{{ 'Excluir' | translate }}</div>
			<div>
				<b>Você deseja excluir essa Ação?</b>
			</div>
		</vuestic-modal>
		<ReplicarAcao
			:acao="acaoEscolhida"
			:responsaveis="responsaveis"
			v-on:success="replicarAcaoSuccess()"
			ref="ReplicarAcao"
		></ReplicarAcao>
		<show-image ref="refShowImage" :link="img" />
	</vuestic-modal>
</template>

<script>
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import showImage from '@/components/user-supervisor/form/modalVisualizarImagem.vue';
import cascader from '@/components/my-components/widget/cascader.vue';
import ReplicarAcao from '../../../components/replicarAcao.vue';
import FinalizarAcao from '../../../components/finalizarAcao.vue';

export default {
  props: [
    'ferramentas',
    'acaoEscolhida',
    'responsaveis',
    'areas',
    'indicadores',
  ],
  components: {
    Multiselect,
    ReplicarAcao,
    FinalizarAcao,
    showImage,
    cascader,
  },
  data() {
    return {
      img: '',
      acao: { indicador: [] },
      ferramentasSelecionadas: [],
      responsavelSelecionado: [],
      responsavelCadastroSelecionado: [],
      areasSelecionada: [],
      erros: null,
      user: { funcionario: {} },
    };
  },
  methods: {
    openImg() {
      this.$refs.refShowImage.open();
    },
    close() {
      this.$refs.largeModalEdict.close();
    },
    replicarAcaoSuccess(data) {
      this.$emit('success', data);
      this.close();
    },
    openModalDelete() {
      this.$refs.modalToDelete.open();
    },
    openModalDublicar() {
      this.$refs.ReplicarAcao.open();
    },
    limparCampos() {
      this.responsavelSelecionado = [];
      this.areasSelecionada = [];
      this.ferramentasSelecionadas = [];
      this.responsavelCadastroSelecionado = [];
    },
    async sincronizar() {
      this.user = JSON.parse(localStorage.user);
      this.acao = { ...this.acaoEscolhida };
      this.responsavelSelecionado = await this.responsaveis.find(
        r => r.id === this.acao.responsavel,
      );
      this.responsavelCadastroSelecionado = await this.responsaveis.find(
        r => r.id === this.acao.responsavel_cadastro,
      );
      this.areasSelecionada = await this.areas.find(
        a => a.id === this.acao.area,
      );
      this.ferramentasSelecionadas = await this.ferramentas.find(
        f => f.id === this.acao.origem,
      );
      this.img = this.acao.imagem || '';
    },
    async open() {
      await this.limparCampos();
      await this.sincronizar();
      this.$refs.largeModalEdict.open();
    },
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    acaoAtrazadaOuNoPrazo() {
      this.acao.situacao =				moment().format('YYYY-MM-DD') <= this.acao.prazo ? 1 : 2;
    },
    cancelarAcao() {
      this.acao.situacao = 4;
      this.editarAcao();
    },
    modalToCancela() {
      this.$refs.largeModalCancelar.open();
    },
    modalToEndAcao() {
      this.$refs.modalToEndAcao.open();
    },
    finalizarAcao(acao) {
      this.acao = acao;
      this.editarAcao();
    },
    limparFinalizacaoDaAcao() {
      this.acao.data_finalização = null;
      this.acao.comentario_finalização = '';
    },
    reabrirAcao() {
      this.limparFinalizacaoDaAcao();
      this.acao.situacao = 1;
      this.editarAcao();
    },
    calcularTananho(tamanho, campo) {
      return this.acao[campo] !== undefined
        ? tamanho - this.acao[campo].length
        : 0;
    },
    deletarAcao() {
      this.acao.excluir = true;
      this.editarAcao();
    },
    verifyImg() {
      if (typeof this.acao.imagem === 'string') {
        delete this.acao.imagem;
      }
    },
    async editarAcao() {
      this.verifyImg();
      if (this.acao.situacao < 3) this.acaoAtrazadaOuNoPrazo();
      const { acao } = this;
      if (acao.data_finalização === '') acao.data_finalização = null;
      this.$axios
        .put(`/api2/acao/${acao.id}.json`, acao)
        .then((res) => {
          this.$emit('success', res.data);
          this.$refs.largeModalEdict.close();
          this.message('success', 'Ação Editada!!!');
          this.erros = null;
        })
        .catch((err) => {
          this.erros = err.response.data;
          this.acao.situacao = this.acaoEscolhida.situacao;
          this.message('error', 'Erro ao Editar!!!');
        });
    },
  },
  watch: {
    responsavelSelecionado(val) {
      this.acao.responsavel = val ? val.id : '';
    },
    responsavelCadastroSelecionado(val) {
      this.acao.responsavel_cadastro = val ? val.id : '';
    },
    areasSelecionada(val) {
      this.acao.area = val ? val.id : '';
    },
    ferramentasSelecionadas(val) {
      this.acao.origem = val ? val.id : '';
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
hr {
	margin: 1.5% !important;
}
.col {
	margin-bottom: 0rem;
	padding-bottom: 0rem;
}

@media screen and (max-width: 450px) {
	.multiselect {
		margin-top: 0% !important;
	}
}
</style>
<style lang="scss">
.has_disable {
	color: #b3b3b3 !important;

	~ .control-label {
		font-size: 0.7rem;
		color: #b3b3b3;
		font-weight: 600;
		text-transform: uppercase;
		top: -0.7rem;
		left: 8px;
		background-color: #fff;
		padding: 0 5px;
	}
}
.desativa {
	pointer-events: none;
	cursor: not-allowed;
}
.foto-icon {
	position: absolute;
	top: 26px;
	right: 9px;
	font-size: 2rem;
}
</style>
