<template>
  <el-cascader
    ref="elCascader"
    class="full-width"
    v-on:focus="handleFocus"
    v-on:blur="handleBlur"
    v-on:expand-change="handleExpandChange"
    v-on:active-item-change="handleActiveItemChange"
    v-on:remove-tag="handleRemoveTag"
    v-on:input="handleInput"
    v-on:change="handleChange"
    v-on:visible-change="handleVisibleChange"
    :options="options"
    :props="props"
    v-model="checkedValue"
    :value="value"
    :disabled="disabled"
    clearable
    :placeholder="placeholder"
  ></el-cascader>
</template>

<script>
export default {
  props: {
    options: Array,
    props: {
      type: Object,
      default: () => ({
        label: 'descricao',
        value: 'id',
        children: 'filhos',
        multiple: true,
        leaf: 'filhos',
        checkStrictly: true,
      }),
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      checkedValue: null,
    };
  },
  methods: {
    clean() {
      this.$refs.elCascader.panel.clearCheckedNodes();
    },
    handleFocus(e) {
      this.$emit('focus', e);
    },
    handleBlur(e) {
      this.$emit('blur', e);
    },
    handleExpandChange(e) {
      this.$emit('expand-change', e);
    },
    handleActiveItemChange(e) {
      this.$emit('active-item-change', e);
    },
    handleRemoveTag(e) {
      this.$emit('remove-tag', e);
    },
    handleInput(e) {
      const element = this.getArrayAdapter(e);
      this.$emit('input', element);
    },
    handleChange(e) {
      const element = this.getArrayAdapter(e);
      this.$emit('change', element);
    },
    handleVisibleChange(e) {
      this.$emit('visible-change', e);
    },
    /* noRepet(list) {
      const array = [...list];
      for (let index = 0; index < array.length; index += 1) {
        const element = array[index];
        for (let indexY = index + 1; indexY < array.length; indexY += 1) {
          const elementY = array[indexY];
          if (element[0] === elementY[0] && element.length !== elementY.length) {
            if (element.length > elementY.length) {
              array.splice(indexY, 1);
            } else {
              array.splice(index, 1);
            }
          }
        }
      }
      return array;
    }, */
    getArrayAdapter(array) {
      const list = array;
      return list.map(l => l[l.length - 1]);
    },
    setArrayAdapter(list, item) {
      const newList = [];
      function functionRecursiveAdapter(array, element) {
        // eslint-disable-next-line consistent-return
        array.forEach((a) => {
          if (a.id === element) {
            newList.push(a.id);
            return functionRecursiveAdapter(list, a.id_superior);
          }
          a.filhos.forEach((b) => {
            if (b.id === element) {
              newList.push(b.id);
              return functionRecursiveAdapter(list, b.id_superior);
            }
            return functionRecursiveAdapter(b.filhos, element);
          });
        });
      }
      functionRecursiveAdapter(list, item);
      return newList.reverse();
    },
  },

  watch: {
    options(val) {
      if (this.value) {
        this.checkedValue = this.value.map(v => this.setArrayAdapter(val, v));
      }
    },
    value(val) {
      if (this.options) {
        this.checkedValue = val.map(v => this.setArrayAdapter(this.options, v));
      }
    },
  },
};
</script>

<style>
</style>
