<template>
  <vuestic-modal
    ref="modal"
    :cancelClass="'none'"
    :okClass="'none'"
  >
    <div slot="title">Responsáveis pelas Ações</div>
    <div class="container">
      <div class="row mb-3">
        <div class="col">

          <form autocomplete="off">
            <multiselect
              v-model="responsavel"
              placeholder="Responsável"
              label="nome"
              selectLabel="Selecione um responsável"
              selectedLabel="Selecionado"
              track-by="id"
              deselectLabel="Remover responsável"
              :close-on-select="false"
              :options="responsaveis"
              :multiple="true"
            >
              <span slot="noResult">Responsável não encontrado.</span>
            </multiselect>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="data-group">
              <vuestic-date-picker
                :config="{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  dateFormat: 'Y-m-d',
								}"
                id="criacao"
                v-model="datas.data_cadastro"
              />
              <label
                class="control-label"
                for="criacao"
              >
                Data de Cadastro
              </label>

            </div>
          </div>
          <span
            class=" alert-danger"
            v-if="erros['cadastro']"
          >{{erros['cadastro']}}</span>
        </div>
        <div class="col">
          <div class="form-group">
            <div class="data-group">
              <vuestic-date-picker
                :config="{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  dateFormat: 'Y-m-d',
								}"
                id="final"
                v-model="datas.prazo"
              />
              <label
                class="control-label"
                for="final"
              >
                Prazo
              </label>
            </div>
          </div>

          <span
            class=" alert-danger"
            v-if="erros['prazo']"
          >{{erros['prazo']}}</span>
        </div>

      </div>
      <div class="row d-flex justify-content-center">
        <button
          :disabled="disabled"
          class="btn-micro btn btn-primary mt-3"
          @click="sendAcao"
        >CONFIRMAR</button>
      </div>
    </div>
  </vuestic-modal>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    responsaveis: {
      type: Array,
    },
    acao: {
      type: Object,
    },
  },
  data() {
    return {
      responsavel: [],
      datas: {
        prazo: null,
        data_cadastro: null,
      },
      erros: {},
    };
  },
  methods: {
    open() {
      this.datas.data_cadastro = moment().format('YYYY-MM-DD');
      this.responsavel = [];
      this.datas.prazo = null;
      this.$refs.modal.open();
    },
    statusDaSituacao() {
      const hoje = moment().format('YYYY-MM-DD');
      const { prazo } = this.datas;
      return hoje <= prazo ? 1 : 2;
    },
    close() {
      this.$refs.modal.close();
    },
    async arrayAcoes() {
      const novaAcao = this.acao;
      delete novaAcao.id;
      const arrayIds = await this.responsavel.map(r => r.id);
      const acoes = [];
      arrayIds.forEach(async (id) => {
        const { ...acao } = novaAcao;
        acao.responsavel = await id;
        acao.prazo = this.datas.prazo;
        acao.data_cadastro = this.datas.data_cadastro;
        acao.situacao = await this.statusDaSituacao();
        acoes.push({ ...acao });
      });
      return acoes;
    },
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    async sendAcao() {
      const acoes = await this.arrayAcoes();
      this.$axios.post('/api2/acao/multiplos/', acoes).then((res) => {
        this.message('success', 'Ação replicada com sucesso');
        this.$emit('success', res);
        this.close();
      }).catch(() => {
        this.message('error', 'Erro!! Não foi possivel replicar a ação');
        this.$emit('error');
      });
    },
  },
  computed: {
    disabled() {
      const disabled = !(this.responsavel.length > 0
        && !!this.datas.prazo
        && !!this.datas.data_cadastro);
      return disabled;
    },
  },
};
</script>

<style>
</style>
